.App-Split-Container {
  margin: 2px;
  padding: 2px;
  width: 0px;
  overflow-x: hidden;
  transition: width 1s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: 1px solid;
}
.App-Split-Container-Show {
  width: 50vw;
}
.App-Split {
  margin: 2px;
  padding: 2px;
  max-height: 100vh;
  overflow-y: scroll;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.App-iframe-container {
  margin: 2px;
  padding: 2px;
  width: 0px;
  overflow-x: hidden;
  transition: width 1s ease;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.App-iframe-container-Show {
  width: 50vw;
  border: 1px solid;
}
.App-iframe {
  /* margin: 2px;
  padding: 2px;
  border: 1px solid; */
  border: none;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
input {
  border: 1px solid;
  font-size: medium;
  color: #aaa;
  background-color: #333;
  text-align: center;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
textarea {
  border: 1px solid;
  color: #aaa;
  background-color: #333;
  text-align: center;
}
select {
  font-size: large;
}
.App-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

::placeholder {
  color: #666;
}

.App {
  text-align: center;
  padding: 1rem;
  overflow: scroll;
  height: 100%;
}

/* .App-button { */
/* background-color: #111;
  border-radius: 25%;
  color: #eee;
  margin: 5vmin;
  padding: 2vmin; */
/* } */

.App-navbutton {
  font-size: 1rem;
  margin: 0.5rem;
  width: 12rem;
  cursor: pointer;
  /* font-size: calc(5px + 2vmin); */
  /* padding: 0.5rem 2.5rem; */
  /* font-size: 2rem; */
  /* border-width: 0.2rem; */
  /* border-radius: 2.5rem; */
}
.App-navbutton .dbd {
  color: #444;
  background-color: #888;
  /* border: 1px solid;
  border-radius: 5px;
  margin: 2px;
  padding: 0px 5px;
  font-size: 1rem; */
}

.App-alert {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: space-between;
  background-color: #111;
  color: #eee;
  position: relative;
  z-index: 99999;
}
.App-Alert-Button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  text-decoration: none;
  background: #333;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 1rem 1rem;
}

.App-Alert-Button:hover,
.App-Alert-Button:focus {
  background: #444;
}

.App-Alert-Button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

.App-Alert-Button:active {
  transform: scale(0.99);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-play-bar {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}
/* .App-play-bar div {
  margin: 10px;
} */

.App-div-button {
  color: #222;
  background-color: #ccc;
  border: 1px solid;
  border-radius: 5px;
  margin: 2px;
  padding: 1px 5px;
  cursor: pointer;
  font-size: 1rem;
  min-height: 1.7rem;
  min-width: 1.7rem;
}
.App-div-button-active {
  background-color: #fff;
}
.App-div-button-anchor {
  text-decoration: none;
  color: #222;
}
.App-div-button:disabled {
  color: #444;
  background-color: #888;
  border: 1px solid;
  border-radius: 5px;
  margin: 2px;
  padding: 0px 5px;
  font-size: 1rem;
}
.App-div-button-disabled {
  color: #444;
  background-color: #888;
  border: 1px solid;
  border-radius: 5px;
  margin: 2px;
  padding: 0px 5px;
  font-size: 1rem;
}

.App-link {
  color: #eee;
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 5px;
}
.App-link-disabled {
  color: #444;
}
.App-Flex-Container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: space-between;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.App-Flex-Container .search-input-3 {
  width: 90%; /*fallback*/
  width: calc(100% - ((3 * 1.7rem) + 1rem));
  position: relative;
  text-align: left;
  margin-left: 0.1rem;
  margin-top: 0.1rem;
  padding-left: 1rem;
}
.App-Flex-Container .search-input-2 {
  width: 90%; /*fallback*/
  width: calc(100% - ((2 * 1.7rem) + 1rem));
  position: relative;
  text-align: left;
  margin-left: 0.1rem;
  margin-top: 0.1rem;
  padding-left: 1rem;
}
.App-Flex-Container .search-input-1 {
  width: 90%; /*fallback*/
  width: calc(100% - ((1 * 1.7rem) + 1rem));
  position: relative;
  text-align: left;
  margin-left: 0.1rem;
  margin-top: 0.1rem;
  padding-left: 1rem;
}
.App-Flex-Container .small-button {
  width: 1.7rem;
  height: 1.7rem;
  text-align: center;
}

.App-Alert-Sticky {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

.Artist-Name-Container {
  display: flex;
  justify-content: space-between;
  border: 1px solid;
  text-align: left;
  cursor: pointer;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1.1rem;
}
.Artist-Name {
  font-size: large;
  font-weight: bold;
  text-align: left;
  align-items: center;
}
.Artist-Right {
  text-align: right;
}

.Credit-Card-Container {
  display: flex;
  justify-content: center;
  width: 100%;
  /* width: 20rem; */
  /* border: 1px solid; */
  text-align: center;
  align-items: center;
  padding: 0.25rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Credit-Card-Input {
  width: 20rem;
  /* font-size: large; */
  /* border: 1px solid; */
  text-align: center;
  align-items: center;
}
/* .Credit-Card-Input * {
  border: 1px solid;
} */

.Fixed-Width-Container {
  display: flex;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Fixed-Width-Content {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* border: 1px solid; */
}
.Fixed-Width-Content input {
  margin: -3px;
  padding: -3px;
  border: 0px;
  width: 70%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}
.Fixed-Width-Content textarea {
  /* height: 1rem; */
  margin: -3px;
  padding: -3px;
  border: 0px;
  width: 70%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}

.Limit-Width-Container {
  display: flex;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Limit-Width-Content {
  max-width: 500px;
  /* margin: 1rem; */
  /* border: 1px solid; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Limit-Width-Content p {
  text-align: justify;
}
/* .Limit-Width-Content li {
  text-align: left;
  cursor: pointer;
} */

.Logged-In-As-Container {
  position: "fixed";
  left: "0";
  top: "0";
  width: "100%";
  text-align: "left";
}

.Logged-In-As {
  font-size: x-small;
}

.Menu-Item-Control {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 20px);
}
.Menu-Item-Control .Menu-Item-Content {
  width: calc(100% - 200px);
}
.Menu-Item-Control .Menu-Item-Content input,
.Menu-Item-Control .Menu-Item-Content textarea {
  width: 100%;
}
.Preferences-Container {
  display: flex;
  justify-content: center;
}
.Preferences-Content {
  max-width: 500px;
}
.Preferences-Section {
  margin-bottom: 1rem;
  border: 1px solid;
  padding: 0.25rem 1rem;
}

.Request-Container {
  display: flex;
  justify-content: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Request-Content {
  /* width: 500px; */
  width: 100%; /* changing from 500px for the scroll */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* border: 1px solid; */
}
.Request-Content input {
  /* margin: -3px; */
  /* padding: -3px; */
  border: 0px;
  width: 90%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}
.Request-Content .Artist-Label {
  font-size: larger;
  font-weight: bold;
  padding-left: 1rem;
}

.Set-Name-Input {
  font-size: large;
  font-weight: bold;
  width: calc(100% - ((1.7rem * 1) + 0.4rem));
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  text-align: left;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 1rem;
}

.Track-List-Container {
  border: 1px solid;
}
.Track-Item {
  border: 1px solid;
  padding: 0.25em;
  cursor: pointer;
  /* display: flex; */
  /* justify-content: center; */
}
.Track-Item-Title {
  padding-left: 1rem;
  font-size: large;
  font-weight: bold;
  /* margin-right: 1rem; */
}
.Track-Err {
  background-color: darkred;
}

.control-container {
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
}
.requested {
  background-color: darkgreen;
}
.played {
  /* background-color: rgb(129, 69, 0); */
  background-color: rgb(95, 48, 0);
  /* background-color: darkgoldenrod; */
}
.OSMD-Container {
  background-color: #fff;
  color: #000;
  height: auto;
}

/* children of this class get the full width */
/* .fullWidth * {
  width: 100%;
} */

.dropdown-input {
  font-size: 16px;
}

/* .features-collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
} */

/* .features .features-active,
.features .features-collapsible:hover {
  background-color: #555;
} */

/* .features {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 2s ease;
}

.features-show {
  max-height: 100%;
} */

.features .features-add {
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 0.25rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #444;
}

.features .features-add:hover {
  background-color: #555;
}

.features .features-header {
  cursor: pointer;
  text-align: left;
  width: 100%;
  padding: 0.25rem;
  /* padding-top: 0.25rem;
  padding-bottom: 0.25rem; */
  /* font-size: larger; */
  /* border: 1px solid; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background-color: #444;
}
.features .features-header:hover {
  background-color: #555;
}
.features .features-content {
  display: none;
  overflow: hidden;
  margin-bottom: 0.25rem;
  /* transition: max-height 0.2s ease-out; */
  padding-left: 1rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  /* border: 1px solid; */
}
.features .features-active {
  background-color: #666;
}
.features img {
  max-width: 200px;
  border: 1px solid;
}
.features .left {
  text-align: left;
}
.features .step {
  margin-top: 0.5rem;
}
.features .just {
  text-align: justify;
}
.Scroll-Container {
  display: flex;
}
.OSMD-Range-Container {
  background-color: #fff;
  color: #000;
}
.OSMD-Post-Container {
  display: block;
  overflow: hidden;
  /* width: "90%"; */
}
.OSMD-Preview-Container {
  background-color: #fff;
  color: #000;
  height: auto;
  /* overflow: hidden;
  display: block; */
}

.Preview-Container {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 3s ease;
}
.Preview-Container-Show {
  max-height: 1500px;
}

.App-Loading-Container {
  display: flex;
  font-size: larger;
  border: 1px solid;
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 10s ease;
}
.App-Loading-Container-Show {
  max-height: 500px;
}

.Email-SignIn {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 1s ease;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Email-SignIn-Show {
  max-height: 500px;
}
.Email-SignIn input {
  width: 15rem;
}
.Email-SignIn .Text-Container {
  display: flex;
  justify-content: center;
}
.Email-SignIn .Text-Content {
  width: 15rem;
}

.Check-Email {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 1s ease;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Check-Email-Show {
  max-height: 500px;
}

.Login-Page img,
.login-button img {
  width: 80px;
  margin-top: -17px;
  margin-bottom: -22px;
  margin-left: -10px;
  overflow: hidden;
}

.Request-Tracklist-Container {
  max-height: calc(100vh - 220px);
  overflow: auto;
  transition: max-height 3s ease;
}
.Request-Tracklist-Container-Show {
  max-height: calc(100vh - 320px);
  overflow: auto;
}
.MyTracks-Tracklist-Container {
  height: calc(100vh - 220px);
  overflow: auto;
}

.Artist-List-Container {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 1s ease;
  /* width: 100%; */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.Artist-List-Container-Show {
  max-height: 500px;
}

.Home-Container h1 {
  margin-top: 0px;
  margin-bottom: 0px;
  /* border: 1px solid; */
}
.Home-Container h6 {
  font-size: xx-small;
  font-weight: normal;
  /* border: 1px solid; */
  margin-top: 0px;
  margin-bottom: 0px;
}
.Home-Container img {
  margin-top: -30px;
  margin-bottom: -20px;
}
.Home-Container p {
  margin: 10px 0px;
}
.MySongs-Loading,
.Tipper-Container,
.Tipper-Processing,
.From-Requests-Preferences,
.Accept-Terms {
  max-height: 0px;
  overflow-y: hidden;
  transition: max-height 1s ease;
}
.MySongs-Loading-Show,
.Tipper-Container-Show,
.Tipper-Processing-Show,
.Accept-Terms-Show {
  max-height: 85px;
}
.From-Requests-Preferences-Show {
  max-height: 500px;
}
